const data = [
  {
    src: "https://archdesignsstudio.com/assets/projects/itcsrinagar/pic1.png",
    text: "1",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/itcsrinagar/pic2.png",
    text: "2",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/itcsrinagar/pic3.png",
    text: "3",
  },
  {
    src: "https://archdesignsstudio.com/assets/projects/itcsrinagar/pic4.png",
    text: "4",
  },
];

export default data;
